
// import { useController } from 'react-hook-form';

import { useInput, required } from 'react-admin';
import ReactAce from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/ext-searchbox';
import { useState } from 'react'

const JsonInput = (props) => {
    const {
        input: { onChange, ...rest },
        meta: { touched, error },
        isRequired
    } = useInput(props);

    const [errorLines, setErrorLines] = useState([]);
    const [jsonError, setJsonError] = useState(null);

    const getErrorLines = value => {
        try {
            JSON.parse(value);
            setJsonError(null)
            return [];
        } catch (error) {
            console.log(error.message);
            setJsonError(error.message);
            const regex = /at position (\d+)/;
            const match = regex.exec(error.message);
            if (match && match[1]) {
                const errorPosition = parseInt(match[1]);
                const lines = value.substr(0, errorPosition).split('\n');
                return [lines.length];
            }
            return [];
        }
    };

    console.log(rest)

    return (
        <div style={error || errorLines.length > 0 ? { border: "1px solid red" } : {}}>
            <ReactAce
                style={{ width: "1000px" }}
                mode="json"
                theme="github"
                editorProps={{ $blockScrolling: true }}
                onChange={(value, e) => {
                    setErrorLines(getErrorLines(value))
                    onChange(value, e)
                }}
                markers={errorLines.map(line => ({
                    startRow: line,
                    startCol: 0,
                    endRow: line,
                    endCol: 1,
                    className: 'error-marker',
                    type: 'text',
                }))}
                setOptions={{ useWorker: true }}
                {...rest}
            />
            {jsonError && <div style={{
                fontStyle: "italic",
                color: "red",
            }}>{jsonError}{", line: " + errorLines[0]}</div>}
        </div >
    );
};

export default JsonInput;
