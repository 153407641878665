import * as React from 'react';
import { memo } from 'react';
import PropTypes from 'prop-types';
import ContentCopyIcon from '@material-ui/icons/FileCopy';

import { Button } from 'react-admin';

class ShowButton extends React.Component {
    constructor(props) {
        super(props);
    }

    copyToClipboard = (content) => {
        if (navigator.clipboard && content) {
            navigator.clipboard.writeText(content.split('<p>').join('').split('</p>').join('\n').split('<br>').join('')).then(
                () => {
                    console.log('Content copied to clipboard:', content);
                },
                (err) => {
                    console.error('Failed to copy content:', err);
                }
            );
        } else {
            console.error('Clipboard API not supported or content is empty.');
        }
    };

    render() {
        const {
            resource,
            basePath = '',
            icon = defaultIcon,
            label = 'Copy Content',
            record,
            scrollToTop = true,
            ...rest
        } = this.props;

        return (
            <div
                style={{
                    position: 'relative',
                }}
            >
                <Button
                    label={label}
                    onClick={() => {
                        if (record && record.content) {
                            this.copyToClipboard(record.content);
                        } else {
                            console.warn('No content found in record.');
                        }
                    }}
                    {...rest}
                >
                    <ContentCopyIcon />
                </Button>
            </div>
        );
    }
}

const defaultIcon = <ContentCopyIcon />;

ShowButton.propTypes = {
    basePath: PropTypes.string,
    icon: PropTypes.element,
    label: PropTypes.string,
    record: PropTypes.any,
    scrollToTop: PropTypes.bool,
};

const PureShowButton = memo(
    ShowButton,
    (props, nextProps) =>
        (props.record && nextProps.record
            ? props.record.id === nextProps.record.id
            : props.record == nextProps.record) && // eslint-disable-line eqeqeq
        props.basePath === nextProps.basePath &&
        props.to === nextProps.to &&
        props.disabled === nextProps.disabled
);

export default PureShowButton;
