
// in app.js
import * as React from "react"
import { Admin, Resource, DeleteButton } from 'react-admin'
import dataProvider from './dataProvider'
import authProvider from './authProvider';
import { i18nProvider as polyglotI18nProvider } from './polyglotProvider'
import Dashboard from './Dashboard'
import Theme from './Theme'
import LoginPage from './pages/LoginPage'
import SearchImages from './pages/SearchImages'
import Menu from './Menu'

import { WebsiteList, WebsiteShow, WebsiteCreate, WebsiteEdit, WebsiteIcon } from './resources/website'
import { MediaList, MediaShow, MediaCreate, MediaEdit, MediaIcon } from './resources/media'
import { PostList, PostShow, PostCreate, PostEdit, PostIcon } from './resources/post'
import { LegaleList, LegaleShow, LegaleCreate, LegaleEdit, LegaleIcon } from './resources/legale'
import { PageList, PageShow, PageCreate, PageEdit, PageIcon } from './resources/page'
import { MenuList, MenuShow, MenuCreate, MenuEdit, MenuIcon } from './resources/menu'
import { TextList, TextShow, TextCreate, TextEdit, TextIcon } from './resources/text'
import { PromptList, PromptShow, PromptCreate, PromptEdit, PromptIcon } from './resources/prompt'
import { BannerList, BannerShow, BannerCreate, BannerEdit, BannerIcon } from './resources/banner'
import { SeopathList, SeopathShow, SeopathCreate, SeopathEdit, SeopathIcon } from './resources/seopath'
import { ExpenseList, ExpenseShow, ExpenseCreate, ExpenseEdit, ExpenseIcon } from './resources/expense'
import { IncomeList, IncomeShow, IncomeCreate, IncomeEdit, IncomeIcon } from './resources/income'
import { TrackingList, TrackingShow, TrackingIcon } from './resources/tracking'
import { ProductList, ProductShow, ProductCreate, ProductEdit, ProductIcon } from './resources/product'
import { CommandList, CommandShow, CommandIcon, CommandEdit } from './resources/commands'
import { ShoppageList, ShoppageShow, ShoppageCreate, ShoppageEdit, ShoppageIcon } from './resources/shoppage'
import { CodeurPreResponseList, CodeurPreResponseShow, CodeurPreResponseCreate, CodeurPreResponseEdit, CodeurPreResponseIcon } from './resources/codeurpreresponse'

import { BrowserRouter as Router, Route } from "react-router-dom";

import Graph from './pages/Graph'
import GraphGain from './pages/GraphGain'
import Gallery from './pages/Gallery'


import { Provider } from 'react-redux';
import { createHashHistory } from 'history';
import createAdminStore from './createAdminStore';
import SearchOpenai from "./pages/SearchOpenai";
import ReviewProductsByOpenai from "./pages/ReviewProductsByOpenai";

const history = createHashHistory();


class App extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      dashboard: null
    }
  }

  render() {
    return (
      <Provider
        store={createAdminStore({
          authProvider,
          dataProvider,
          history,
        })}
      >
        <Admin
          theme={Theme}
          menu={Menu((dashboard) => this.setState({ dashboard }))}
          dataProvider={dataProvider}
          authProvider={authProvider}
          dashboard={Dashboard(this.state.dashboard)}
          i18nProvider={polyglotI18nProvider}
          loginPage={LoginPage}
          history={history}
          customRoutes={[
            <Route exact path="/graph" component={Graph} />,
            <Route exact path="/graph-gain" component={GraphGain} />,
            <Route exact path="/search-images" component={SearchImages} />,
            <Route exact path="/search-openai" component={SearchOpenai} />,
            <Route exact path="/gallery" component={Gallery} />,
            <Route exact path="/reviewproductsbyopenai" component={ReviewProductsByOpenai} />,
          ]}
        >
          <Resource name="Media" options={{ group: "Configuration" }} list={MediaList} show={MediaShow} create={MediaCreate} edit={MediaEdit} icon={MediaIcon} />
          <Resource name="Text" options={{ group: "Configuration" }} list={TextList} show={TextShow} create={TextCreate} edit={TextEdit} icon={TextIcon} />
          <Resource name="Prompt" options={{ group: "Configuration" }} list={PromptList} show={PromptShow} create={PromptCreate} edit={PromptEdit} icon={PromptIcon} />
          <Resource name="CodeurPreResponse" options={{ group: "Leads" }} list={CodeurPreResponseList} show={CodeurPreResponseShow} create={CodeurPreResponseCreate} edit={CodeurPreResponseEdit} icon={CodeurPreResponseIcon} />
          <Resource name="Website" options={{ group: "Websites" }} list={WebsiteList} show={WebsiteShow} create={WebsiteCreate} edit={WebsiteEdit} icon={WebsiteIcon} />
          <Resource name="Page" options={{ group: "Websites" }} list={PageList} show={PageShow} create={PageCreate} edit={PageEdit} icon={PageIcon} />
          <Resource name="Menu" options={{ group: "Websites" }} list={MenuList} show={MenuShow} create={MenuCreate} edit={MenuEdit} icon={MenuIcon} />
          <Resource name="Post" options={{ group: "Websites" }} list={PostList} show={PostShow} create={PostCreate} edit={PostEdit} icon={PostIcon} />
          <Resource name="Legale" options={{ group: "Websites" }} list={LegaleList} show={LegaleShow} create={LegaleCreate} edit={LegaleEdit} icon={LegaleIcon} />
          <Resource name="Banner" options={{ group: "Websites" }} list={BannerList} show={BannerShow} create={BannerCreate} edit={BannerEdit} icon={BannerIcon} remove={DeleteButton}  />
          <Resource name="Seopath" options={{ group: "Seo" }} list={SeopathList} show={SeopathShow} create={SeopathCreate} edit={SeopathEdit} icon={SeopathIcon} remove={DeleteButton} />
          <Resource name="ShopPage" options={{ group: "Shop" }} list={ShoppageList} show={ShoppageShow} create={ShoppageCreate} edit={ShoppageEdit} icon={ShoppageIcon} remove={DeleteButton} />
          <Resource name="Product" options={{ group: "Shop" }} list={ProductList} show={ProductShow} create={ProductCreate} edit={ProductEdit} icon={ProductIcon} remove={DeleteButton} />
          <Resource name="Command" options={{ group: "Shop" }} list={CommandList} show={CommandShow} edit={CommandEdit} icon={CommandIcon} />
          <Resource name="Expense" options={{ group: "Gain" }} list={ExpenseList} show={ExpenseShow} create={ExpenseCreate} edit={ExpenseEdit} icon={ExpenseIcon} />
          <Resource name="Income" options={{ group: "Gain" }} list={IncomeList} show={IncomeShow} create={IncomeCreate} edit={IncomeEdit} icon={IncomeIcon} />
          <Resource name="Tracking" options={{ group: "Tracking" }} list={TrackingList} show={TrackingShow} icon={TrackingIcon} remove={DeleteButton} />
        </Admin>
      </Provider>
    )
  }
}

export default App



